<div style="text-align: center;">
  <img src="/assets/img/yay.gif" alt=""/>
</div>


<div *ngIf="formSubmitted==false">
  <h1>YAY! Lucky you.</h1>

  <p>Registrera dig nedan & få 25 credits (= 25kr) på ditt YAY VR konto för att boka ditt första spel. Från 99kr/spel.<br><br>
  </p>

  <br>

  <form [formGroup]="waitingForm" (submit)="onSubmit()">
    <div class="flex-row">
      <div style="width: 100%;">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Förnamn</mat-label>
          <input matInput required formControlName="firstName">
        </mat-form-field>
      </div>
      <div class="col-right">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Efternamn</mat-label>
          <input matInput required formControlName="lastName">
        </mat-form-field>
      </div>
    </div>
    <div class="flex-row">
      <div style="width: 100%;">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Mobilnummer</mat-label>
          <input matInput required formControlName="tel" type="tel">
        </mat-form-field>
      </div>
      <div class="col-right">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>E-mail</mat-label>
          <input matInput required formControlName="email" type="email">
        </mat-form-field>
      </div>
    </div>

    <p style="font-size: 14px;">
      Detta erbjudande gäller endast nya användare samt kan användas en gång.
    </p>

    <p *ngIf="hasError" class="hasError">
      Ditt telefonnummer finns redan registrerat hos YAYVR, men använd <strong>#yayvr</strong> när du postar bilder & videos från dina spel på Instagram & Tik Tok för att få gratis credits att spela för:)
    </p>

    <button class="btn" style="display: block; width: 100%;" type="submit"
            [disabled]="!waitingForm.valid">GÅ VIDARE
    </button>


    <p class="smaller">
      Genom att gå vidare godkänner du att vi sparar din information för att registrera ett användarkonto på YAY VR samt att du har läst och förstått <a
      href="https://www.yayvr.se/policys-terms-and-conditions/" target="_blank">våra villkor</a>.
    </p>

    <mat-progress-bar mode="indeterminate" style="margin-top: 20px;" *ngIf="isLoading"></mat-progress-bar>
  </form>
</div>

<div *ngIf="formSubmitted">
  <h1>Nice! </h1>
  <p>
    Go to <a href="https://app.yayvr.se" target="_blank">app.yayvr.se</a> or download our Apple / Android app to verify
    your phone number and make your first booking :)<br>
  </p>

  <div style="display: flex; flex-direction: row; text-align: center">
    <div style="width: 100%;">
      <a href="https://apps.apple.com/se/app/yay-vr/id1571935896" rel="noopener" aria-label="open apple application store"
      target="_blank"><img src="/assets/img/appstore.png" alt="App store"></a>
    </div>
    <div style="width: 100%;">
      <a href="https://play.google.com/store/apps/details?id=se.xrtech.yayapp" rel="noopener"
      aria-label="open google play store" target="_blank"><img src="/assets/img/google-play.png" alt="Google play"></a>
    </div>
  </div>

</div>
<br>
<br>
<img src="/assets/img/yay-ver-wmos.png" alt="YAY VR">
<h1>Vad är YAY VR?</h1>
<p>I YAY VR's self-service arkader är det enkelt och roligt att testa och spela fantastiska Virtual Reality upplevelser.<br><br>
  Testa VR för första gången, utmana dina vänner eller ta med kollegorna på en AW - vi har upplevelser för alla!<br>


  Läs mer på <a href="https://www.yayvr.se" target="_blank">yayvr.se</a>.

  <br><br>
  <strong>Och följ oss på <a href="https://www.instagram.com/yay_vr/" target="_blank">Instagram</a></strong></p>

<br><br>
<br><br>
<br><br>
<br><br>
