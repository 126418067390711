import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';

declare var gtag;

@Component({
  selector: 'app-waitlist',
  templateUrl: './waitlist.component.html',
  styleUrls: ['./waitlist.component.scss']
})
export class WaitlistComponent implements OnInit {

  public waitingForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    tel: ['', Validators.required],
    email: ['', Validators.required]
  });

  public shareFriendsForm = this.fb.group({
    tel1: [''],
    tel2: [''],
    tel3: [''],
    tel4: [''],
    tel5: [''],
    tel6: [''],
  });

  public formSubmitted = false;

  public isLoading = false;

  public totalCredits = 50;

  public hasError = false;

  public ref = null;

  constructor(private fb: FormBuilder, private http: HttpClient, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.route.snapshot.params.ref) {
      this.ref = this.route.snapshot.params.ref;
    }
  }

  onSubmit(): void {
    this.isLoading = true;

    const waitingFormValues = this.waitingForm.value;
    waitingFormValues.campaign = '2225campaign';
    this.http.post(environment.apiHost + '/campaign/submission', this.waitingForm.value)
      .subscribe(() => {

        gtag('event', 'CAMPAIGN_FORM', {
          event_category: 'FORM_SUBMITTED',
          event_label: 'Submitted form'
        });

        this.isLoading = false;
        this.hasError = false;
        this.formSubmitted = true;

        // tslint:disable-next-line:triple-equals
        if (this.ref == 'taby') {
          document.location.href = 'https://app.yayvr.se/book/location/624c18a29e3eb';
          // tslint:disable-next-line:triple-equals
        } else if (this.ref == 'wmos') {
          document.location.href = 'https://app.yayvr.se/book/location/60d24a25801f0';
        } else {
          document.location.href = 'https://app.yayvr.se/';
        }

      }, (error) => {
        this.isLoading = false;
        this.hasError = true;
      });
  }

  calcPoints(): void {
    let initpoints = 40;
    if (this.hasFriend('tel1')) {
      initpoints += 10;
    }
    if (this.hasFriend('tel2')) {
      initpoints += 10;
    }
    if (this.hasFriend('tel3')) {
      initpoints += 10;
    }
    if (this.hasFriend('tel4')) {
      initpoints += 10;
    }
    if (this.hasFriend('tel5')) {
      initpoints += 10;
    }
    if (this.hasFriend('tel6')) {
      initpoints += 10;
    }

    this.totalCredits = initpoints;
  }

  hasFriend(field): boolean {
    return this.waitingForm.get(field).value.length > 9;
  }

}
