<br>
<img src="/assets/img/epi-poster2.png" alt="Epicenter YAY VR" style="max-width: 100%;"> <br><br>
<iframe src="https://player.vimeo.com/video/570335625?color&amp;autopause=0&amp;loop=0&amp;muted=0&amp;title=0&amp;portrait=0&amp;byline=0#t=" width="631" height="360" frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
<br>

<div *ngIf="formSubmitted==false">
  <h1>Hämta ditt gratis spel!</h1>

  <p>Bli först med att testa Sveriges nya VR upplevelse som snart lanseras i en galleria nära dig.<br><br>

    Nu är vår första (BETA) VR kub öppen för inbjudna och besökare till Epicenter Stockholm och här kan du registrera dig för att få ett GRATIS SPEL (credits). Du får då även vara med på Epicenter VR CUP topplistan med fina vinster i potten!</p>

  <br>

  <form [formGroup]="waitingForm" (submit)="onSubmit()">
    <div class="flex-row">
      <div style="width: 100%;">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Förnamn</mat-label>
          <input matInput required formControlName="firstName">
        </mat-form-field>
      </div>
      <div class="col-right">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Efternamn</mat-label>
          <input matInput required formControlName="lastName">
        </mat-form-field>
      </div>
    </div>
    <div class="flex-row">
      <div style="width: 100%;">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Mobilnummer</mat-label>
          <input matInput required formControlName="tel" type="tel">
        </mat-form-field>
      </div>
      <div class="col-right">
        <p style="font-size: 14px;">Vi laddar ditt mobilnummer med credits för ett gratis spel!</p>
      </div>
    </div>
    <p style="font-size: 10px;">
      * Obligatoriskt.
    </p>

    <h2>Glöm inte familj & vänner!</h2>
    <p>Var schysst och sätt upp dina kollegor på listan så får de ett SMS med samma erbjudande. </p>

    <div class="flex-row">
      <div style="width: 100%;">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Mobilnummer vän 1</mat-label>
          <input matInput type="tel" formControlName="tel1">
        </mat-form-field>
      </div>
      <div class="col-right">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Mobilnummer vän 2</mat-label>
          <input matInput type="tel" formControlName="tel2">
        </mat-form-field>
      </div>
    </div>
    <div class="flex-row">
      <div style="width: 100%;">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Mobilnummer vän 3</mat-label>
          <input matInput type="tel" formControlName="tel3">
        </mat-form-field>
      </div>
      <div class="col-right">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Mobilnummer vän 4</mat-label>
          <input matInput type="tel" formControlName="tel4">
        </mat-form-field>
      </div>
    </div>
    <div class="flex-row">
      <div style="width: 100%;">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Mobilnummer vän 5</mat-label>
          <input matInput type="tel" formControlName="tel5">
        </mat-form-field>
      </div>
      <div class="col-right">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>Mobilnummer vän 6</mat-label>
          <input matInput type="tel" formControlName="tel6">
        </mat-form-field>
      </div>
    </div>

    <button mat-raised-button style="display: block; width: 100%;" color="primary" type="submit"
            [disabled]="!waitingForm.valid">SKICKA
    </button>

    <mat-progress-bar mode="indeterminate" style="margin-top: 20px;" *ngIf="isLoading"></mat-progress-bar>
  </form>
</div>

<div *ngIf="formSubmitted">
  <h1>YAY! Nu kan du boka.</h1>
  <p>Gå direkt till VR Kuben för att spela/boka på skärmen utanför eller förboka en tid på: <a href="https://app.yayvr.se">app.yayvr.se</a><br><br>

    Välj ”Street Beat: New York” spelet för att vara med i Epicenter VR Cup. Om du har fått credits att spela för visas dessa efter att du verifierat ditt mobilnummer.<br><br>

    LYCKA TILL!<br><br>


    Läs mer om oss på: <a href="https://xr-tech.se" target="_blank">xr-tech.se</a></p>
</div>
<br>
