<div class="main">
  <div style="text-align: center; margin: 60px 0 40px 0;">
  <img src="/assets/img/yay-logo.png" alt="YAY VR" style="max-width: 190px" />
  </div>
  <router-outlet></router-outlet>

  <footer>
  <br>
  <hr>
    <p class="copyright7">YAY VR is a part of <a href="https://xr-tech.se">XR Tech</a>.<br>© XR Tech, 2022, All rights reserved. - <a href="https://www.yayvr.se/policys-terms-and-conditions/" target="_blank">Allmänna villkor</a></p>
  </footer>
</div>

