import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {WaitlistComponent} from './waitlist/waitlist.component';

const routes: Routes = [
  {
    path: '',
    component: WaitlistComponent
  },
  {
    path: ':ref',
    component: WaitlistComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
