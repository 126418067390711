<img src="/assets/img/yay-vip-banner.png" alt="YAY VR">
<br>
<h1>YAY! Nu kommer vi.</h1>
<p>Bli först med att testa Sveriges nya VR upplevelse som snart lanseras i en galleria nära dig, med BETA start i Stockholm!<br><br>
  Vi delar nu ut GRATIS SPEL (credits) till dig som skriver upp dig på väntelistan.</p>

<a href="#" routerLink="/vip" class="btn">VÄNTELISTAN</a>


<br><br>
<hr>
<br><br>
<iframe src="https://player.vimeo.com/video/570335625?color&amp;autopause=0&amp;loop=0&amp;muted=0&amp;title=0&amp;portrait=0&amp;byline=0#t=" width="631" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
<br>
<p>I YAY VR's self-service arkader är det enkelt och roligt att testa och spela fantastiska Virtual Reality upplevelser.<br>
  Testa VR för första gången, utmana dina vänner eller ta med kollegorna på en AW - vi har upplevelser för alla! Beta testas nu.</p>

