import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

declare var gtag;

@Component({
  selector: 'app-epicenter',
  templateUrl: './epicenter.component.html',
  styleUrls: ['./epicenter.component.scss']
})
export class EpicenterComponent implements OnInit {

  public waitingForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    tel: ['', Validators.required],
    tel1: [''],
    tel2: [''],
    tel3: [''],
    tel4: [''],
    tel5: [''],
    tel6: [''],
  });

  public formSubmitted = false;

  public isLoading = false;

  constructor(private fb: FormBuilder, private http: HttpClient) {
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    gtag('event', 'EPICENTER_FORM', {
      event_category: 'FORM_SUBMITTED',
      event_label: 'Submitted form'
    });

    this.isLoading = true;

    this.http.post('https://waitinglist.yayvr.se/waitinglist/submission_epi', this.waitingForm.value)
      .subscribe(() => {
        this.formSubmitted = true;
      });
  }

  pickContact(): void {
    // @ts-ignore
    if ('contacts' in navigator && 'select' in navigator.contacts && 'getProperties' in navigator.contacts) {
      try {
        // @ts-ignore
        const availableProperties = navigator.contacts.getProperties();

        if (availableProperties.includes('address')) {
          const contactProperties = ['name', 'tel', 'address'];

          // @ts-ignore
          const contacts = navigator.contacts
            .select(
              contactProperties,
              {multiple: true}
            );

          console.log('Your first contact: ' + contacts[0].name + ' ' + contacts[0].tel + ' ' + contacts[0].address);
        } else {
          console.log('Contact Picker API on your device doesn\'t support address property');
        }
      } catch (e) {
        console.log('Unexpected error happened in Contact Picker API');
      }
    } else {
      console.log('Your browser doesn\'t support Contact Picker API');
    }
  }
}
